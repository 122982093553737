
/**************************************************
* Popup Override
**************************************************/
.chakra-radio__input:checked ~ .chakra-radio__control::before {
    background-color: #136AFF ;
    border: 2px solid #FFFFFF ;
    width: 100%;
    height: 100%;
    border-radius: 50% ;
  }

.chakra-radio__control::before {
    background-color: #136AFF ;
}

.chakra-radio__input ~ .chakra-radio__control {
    border: 1px solid #ACCBFF ;
}
